import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'
import { ButtonLink, DisabledButton } from 'components/styled/button'
import ArrowIcon from 'svgs/arrow_top_right.svg'
import CreditCardsIcon from 'images/credit-cards.png'

import { SCREEN } from 'styles/screens'
import { config } from '../../config'
import RenderHtml from '../shared/renderHtml'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 50px 40px 100px;
  min-height: 800px;
  > div:first-child {
    display: none;
  }
  .fs-15px {
    font-size: 15px;
  }
  .bg-secondary {
    background-color: var(--secondary);
  }
  .rounded-13px {
    border-radius: 13px;
  }
  .rounded-8px {
    border-radius: 8px;
  }
  .pa-12px {
    padding: 12px 15px;
  }
  .text-decoration-line-through {
    text-decoration: line-through;
  }
  .vertical-align-text-top {
    vertical-align: text-top;
  }
  .fs-45px {
    font-size: 45px;
  }
  .fs-23px {
    font-size: 23px;
  }
  .fs-35px {
    font-size: 35px;
    line-height: 1;
  }
  .mx-1rem {
    margin-left: 25px;
    margin-right: 25px;
  }
  .w-max-content {
    width: max-content;
  }
  .fw-light {
    font-weight: lighter !important;
  }
  .fw-normal {
    font-weight: 400 !important;
  }
  .text-dark-gray {
    color: #6c6c6c;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    > div:first-child {
      align-self: start;
      display: block;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    [class^='typography__ShadowContainer'] h3 {
      font-size: 28px !important;
    }
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  margin-top: 52px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  max-width: 1520px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 0;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 0 1 56%;
  p {
    font-weight: 400;
    line-height: 1.8;
    font-size: 27px;
    letter-spacing: -0.1px;
    max-width: 680px;
  }
  p:last-child {
    margin-top: 30px;
    margin-top: 6px;
    letter-spacing: -0.3px;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    > div:first-child {
      display: none;
    }
  }
`

const ImageContainer = styled.div`
  flex: 0 1 44%;
  margin-top: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  > div:first-child {
    flex: 0 1 50%;
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
  img {
    max-width: 100%;
    height: auto;
    max-height: 500px;
  }
  button {
    font-weight: 400;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-top: 0;
    order: -1;
    [data-gatsby-image-wrapper] {
      width: 100%;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-top: 20px;
    > div:first-child {
      margin: 24px 12px;
      &:before {
        right: -10px;
        top: -10px;
      }
      &:after {
        bottom: -3px;
        left: -10px;
        border: 1px solid var(--primary);
      }
    }
  }
`

const ProgramHeader = ({
  title,
  description1,
  description2,
  image,
  apply_url,
}) => (
  <Container>
    <ShadowText highlight>
      <h3 className="ls-n1 lh-2 mt-1">{title}</h3>
    </ShadowText>
    <FixedContainer>
      <DescriptionContainer>
        <ShadowText highlight>
          <h3 className="ls-n1 lh-2 mt-1">{title}</h3>
        </ShadowText>

        <p className="fs-30px mt-0">
          <RenderHtml html={description1} />
        </p>
        <p className="fs-30px">
          <RenderHtml html={description2} />
        </p>
      </DescriptionContainer>

      <ImageContainer>
        <div className="m-0">
          <img
            src={`${config.CDN_BASE_URL}/${image}`}
            alt="meeting"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </div>
        <div className="ml-33px mw-500px mb-mobile-50px">
          <h6 className="mb-0 bg-secondary rounded-8px d-flex align-items-center w-max-content pa-12px">
            {/* <CreditCardsIcon className="mr-8px" /> */}
            <img src={CreditCardsIcon} className="mr-8px" alt="" />
            {getTranslation('learn_later', 'Learn Now and Pay Later	')}
          </h6>
          <p className="fs-15px">
            {getTranslation(
              'this_payment',
              'This payment is paid in 17 months with $100 installments once we boost your salary.',
            )}
          </p>

          <h4 className="fs-35px">
            {getTranslation('our_price', 'Our Price:	')}{' '}
            <span className="bg-secondary rounded-13px pa-12px">
              {getTranslation('1700', '$1700')}
            </span>
          </h4>
          <h4 className="fs-23px text-dark-gray">
            <span className="fw-normal">
              {getTranslation('industry_price', 'Industry price:	')}
            </span>
            <span className="text-decoration-line-through fw-normal">
              {getTranslation('4000', '$4000	')}
            </span>
          </h4>

          <h6>
            {getTranslation('Become one of us', 'Become one of us', false)}
          </h6>
          {apply_url ? (
            <ButtonLink href={apply_url} primary>
              {getTranslation('Apply as Student', 'Apply as Student', false)}{' '}
              <ArrowIcon className="ml-2" />
            </ButtonLink>
          ) : (
            <DisabledButton>
              {getTranslation('Apply as Student', 'Apply as Student', false)}{' '}
              <ArrowIcon className="ml-2" />
            </DisabledButton>
          )}
          <div>
            {getTranslation(
              'Application Will Be Published',
              'Application will be published on 31st of Jan, 12 PM EVN time.',
            )}
          </div>
        </div>
      </ImageContainer>
    </FixedContainer>
  </Container>
)

export default ProgramHeader
